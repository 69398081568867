<template>
  <v-app>
    <loader />
    <core-drawer />
    <core-view />
  </v-app>
</template>

<script>
export default {
  components: {
    Loader: () => import("@/components/core/Loader"),
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreView: () => import("@/components/core/View"),
  },
};
</script>

<style lang="scss" scoped></style>
