import moment from 'moment-timezone';

export const formatCurrency = (item) => {
  return item.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
};

export const formatDecimal = (val, decimal) => {
  return (Math.round(val * 100) / 100).toFixed(decimal);
};

export const computeDiff = (post, pre, decimal) => {
  const difference = ((post - pre) / pre) * 100;
  const formatDiff = (Math.round(difference * 100) / 100).toFixed(decimal);
  return formatDiff;
};

export const numFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const computeTotal = (arr, field) => {
  return arr.reduce((a, b) => a + b[field], 0);
};

export const generateMetric = (arr, current, optimized, simulated) => {
  const current_total = computeTotal(arr, current);
  const optimized_total = computeTotal(arr, optimized);
  const simulated_total = computeTotal(arr, simulated);
  return {
    current: current_total,
    optimized: optimized_total,
    simulated: simulated_total,
  };
};

export const computeOverallRoi = (impact, unit_price, spend) => {
  return ((impact * unit_price) / spend) * 0.98;
};

export const generateRoiMetric = (impact, unit_price, spend) => {
  const current_overall = computeOverallRoi(
    impact["current"],
    unit_price,
    spend["current"]
  );
  const optimized_overall = computeOverallRoi(
    impact["optimized"],
    unit_price,
    spend["optimized"]
  );
  const simulated_overall = computeOverallRoi(
    impact["simulated"],
    unit_price,
    spend["simulated"]
  );
  return {
    current: current_overall,
    optimized: optimized_overall,
    simulated: simulated_overall,
  };
};

export const numberWithCommas = (x) => {
  // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  const integer = x.toString().split(".")[0];
  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatTimeToClientTimezone = (timestamp) => {
  // Get the client's timezone using moment-timezone
  const userTimeZone = moment.tz.guess();

  // Convert GMT+0 timestamp to client timezone
  const localDate = moment.tz(timestamp, 'UTC').tz(userTimeZone);

  // Return the formatted date (you can change the format as per your requirement)
  return localDate.format('YYYY-MM-DD HH:mm:ss');
}