import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const isAdmin = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData.is_admin) {
    return true;
  } else {
    return false;
  }
};

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/datasetGroups",
    name: "dataset-groups,",
    component: () => import("@/views/dataset-groups/DatasetGroups.vue"),
  },
  {
    path: "/datasetGroups/create",
    name: "dataset-groups-create,",
    component: () =>
      import(
        "@/views/dataset-groups/dataset-groups-create/DatasetGroupsCreate.vue"
      ),
  },
  {
    path: "/datasetGroups/:id",
    name: "dataset-groups-view,",
    component: () => import("@/views/dataset-groups/DatasetGroupsView.vue"),
    redirect: "/datasetGroups/:id/overview",
    children: [
      // {
      //   // dataset-groups-tab-info will be rendered inside DatasetGroups's <router-view>
      //   // when /datasetGroups/:id/info is matched
      //   path: "info",
      //   name: "dataset-groups-tab-info",
      //   component: () =>
      //     import("@/views/dataset-groups/dataset-groups-tab-info/DatasetGroupsInfo.vue"),
      // },
      {
        // dataset-groups-tab-causal-graph will be rendered inside DatasetGroups's <router-view>
        // when /datasetGroups/:id/causal-graph is matched
        path: "causal-graph",
        name: "dataset-groups-tab-causal-graph",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-tab-causal-graph/DatasetGroupsCausalGraph.vue"
          ),
      },
      {
        path: "causal-editor",
        name: "dataset-groups-tab-causal-graph-editor",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-tab-causal-graph/DatasetGroupsCausalEditor.vue"
          ),
      },
      {
        path: "overview",
        name: "dataset-groups-overview",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-overview/DatasetGroupsOverview.vue"
          ),
      },
      {
        path: "model-create",
        name: "dataset-groups-tab-model-create",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-tab-model-create/DatasetGroupsModelCreate.vue"
          ),
      },
      {
        path: "open-source-data",
        name: "dataset-groups-tab-open-source-data",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-tab-open-source-data/DatasetGroupsOpenSourceData.vue"
          ),
      },
      {
        path: "open-source-data-view",
        name: "dataset-groups-tab-open-source-view",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-tab-open-source-data/DatasetGroupsOpenSourceView.vue"
          ),
      },
      {
        path: "dataset-groups-data-view",
        name: "dataset-groups-data-view",
        component: () =>
          import(
            "@/views/dataset-groups/dataset-groups-overview/DatasetGroupsDataView.vue"
          ),
      },
      {
        path: "dataset-models",
        name: "dataset-models",
        component: () => import("@/views/models/Models.vue"),
      },
    ],
  },
  {
    path: "/models",
    name: "models",
    component: () => import("@/views/models/Models.vue"),
  },
  {
    path: "/results",
    name: "results",
    component: () => import("@/views/results/Results.vue"),
  },
  // {
  //   path: "/docs",
  //   name: "docs",
  //   component: () => import("@/views/docs/Overview.vue"),
  // },
  // {
  //   path: "/docs/functionality",
  //   name: "functionality",
  //   component: () => import("@/views/docs/Functionality.vue"),
  // },
  // {
  //   path: "/docs/metric_definitions",
  //   name: "metric_definitions",
  //   component: () => import("@/views/docs/MetricDefinitions.vue"),
  // },
  // {
  //   path: "/docs/filter_definitions",
  //   name: "filter_definitions",
  //   component: () => import("@/views/docs/FilterDefinitions.vue"),
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   component: () => import("@/views/Setting.vue"),
  // },
  {
    path: "/docs",
    name: "Docs",
    beforeEnter() {
      window.open(
        "/doc/karma360-quick-start-guide.html",
        "_blank"
      );
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    beforeEnter() {
      window.open("https://forms.gle/ATiJmu2AL9Bgqmpw6", "_blank");
    },
  },
  {
    path: "/users",
    name: "Users,",
    component: () => import("@/views/users/Users.vue"),
    beforeEnter: (to, from, next) => {
      // Check if the user is an admin before entering the route
      if (isAdmin()) {
        next(); // Allow access to the route
      } else {
        // Redirect or handle unauthorized access
        next({ name: "dashboard" }); // Redirect to the dashboard, for example
      }
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: function(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

// // ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
// router.beforeEach((to, _, next) => {
//   const userData = localStorage.getItem('userData')
//   // const isLoggedIn = userData && localStorage.getItem('accessToken')
//   const isLoggedIn = userData && "tesfd123123ffdfafsa"

//   // Go to login page
//   if (to.name === 'auth-login') return next()

//   // If not loggined, go to login page
//   if (!isLoggedIn) return next({ name: 'auth-login'})

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     next('/')
//   }

//   // Refresh the accessToken with a refresh token

//   // Redirect to login page if not loggedIn or the accessToken is expired

//   return next()
// })

export default router;
