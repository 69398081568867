import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: localStorage.getItem("dark_theme") === "true",
    themes: {
      light: {
        // background: '#ffffff',
        background: "#EEF2F8",
        primary: "#7868e6",
        borderColor: "#7868E6",
        increase: "#37BD2D",
        decrease: "#d64550",
        chartFont: "#666",
      },
      dark: {
        background: "#1E1E1E",
        primary: "#F7C23F",
        borderColor: "#F7C23F",
        increase: "#43A269",
        decrease: "#E1765D",
        drawer: "#191B1C",
        chartFont: "#D4D4D4",
      },
    },
  },
});
