<template>
  <component :is="resolveLayoutVariant">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutContentVerticalNav from "@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue";
import LayoutBlank from "@/layouts/variants/blank/LayoutBlank.vue";


// (function(apiKey){
//     (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
//     v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
//         o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
//         y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
//         z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
        // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
        // Call this function after users are authenticated in your app and your visitor and account id values are available
        // Please use Strings, Numbers, or Bools for value types.
        // window.pendo.initialize({
        //     visitor: {
        //         id:              'peter-demo8'   // Required if user is logged in, default creates anonymous ID
        //         // email:        // Recommended if using Pendo Feedback, or NPS Email
        //         // full_name:    // Recommended if using Pendo Feedback
        //         // role:         // Optional

        //         // You can add any additional visitor level key-values here,
        //         // as long as it's not one of the above reserved names.
        //     },

        //     account: {
        //          id:           'peter-demo7' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        //         // name:         // Optional
        //         // is_paying:    // Recommended if using Pendo Feedback
        //         // monthly_value:// Recommended if using Pendo Feedback
        //         // planLevel:    // Optional
        //         // planPrice:    // Optional
        //         // creationDate: // Optional

        //         // You can add any additional account level key-values here,
        //         // as long as it's not one of the above reserved names.
        //     }
        // });
// })('87545264-1cad-4681-6c6a-b0a59205d647');



export default {
  name: "App",
  components: {
    LayoutBlank,
    LayoutContentVerticalNav,
  },
  computed: {
    resolveLayoutVariant() {
      if (this.$route.meta.layout === "blank") return "layout-blank";
      else {
        return `layout-content-vertical-nav`;
      }
    },
  },
};
</script>

<style >
#app {
  font-family: Arial, Helvetica, sans-serif;
}
.headline,
.title,
.subheading {
  font-family: Arial, Helvetica, sans-serif !important;
}
</style>
