import axios from "axios";
import store from "./store";
import router from "@/router"; // Import the router instance

const baseURL = process.env.VUE_APP_API_URL;

const instance = axios.create({
  // Large causal graph will exceed 10s
  timeout: 90000,
});

// https://adrien.poupa.fr/creating-a-global-loader-component-in-vue-js/
instance.defaults.showLoader = true;

let isRefreshing = false; // Flag to track refresh token attempt

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    // eslint-disable-next-line no-param-reassign
    if (accessToken && refreshToken) {
      // If token exists, set the Authorization header
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    if (config.showLoader) {
      store.dispatch("loader/pending");
    }
    return config;
  },
  (error) => {
    if (error.config.showLoader) {
      store.dispatch("loader/done");
    }
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response.config.showLoader) {
      store.dispatch("loader/done");
    }

    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem("refreshToken");

    if (
      error.response.status === 401 && // Unauthorized error
      refreshToken &&
      !originalRequest._retry && // Prevent infinite loop
      !isRefreshing // Check if refresh token attempt is already in progress
    ) {
      isRefreshing = true; // Set the flag to indicate refresh token attempt

      // Set the new token in the Authorization header
      instance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refreshToken}`;

      // Refresh the token using the refresh token
      return instance
        .post(`${baseURL}/user/refresh`)
        .then((response) => {
          if (response.status === 200) {
            const newToken = response.data.access_token;

            // Update the token in local storage
            localStorage.setItem("accessToken", newToken);

            // Set the new token in the Authorization header
            instance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newToken}`;

            // Retry the original request
            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            // Dispatch the "loader/done" action before retrying the request
            store.dispatch("loader/done");

            // Reset the refresh token flag
            isRefreshing = false;

            return instance(originalRequest);
          }
        })
        .catch((error) => {
          // Handle token refresh error, e.g., redirect to login page
          console.error("Token refresh error:", error);
          // Hide the loading before redirect to the login page
          if (error.config.showLoader) {
            store.dispatch("loader/done");
          }
          // Redirect to login page or handle error appropriately
          router.push({ name: "auth-login" });
          throw error; // Throw the error to reject the promise chain
        });
    } else if (!refreshToken) {
      // Redirect to login page or handle error appropriately
      router.push({ name: "auth-login" });
    }

    if (error.config.showLoader) {
      store.dispatch("loader/done");
    }

    return Promise.reject(error);
  }
);

export default instance;
