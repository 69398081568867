const state = {
  dark_theme: localStorage.getItem('dark_theme') || 'false',
  language: localStorage.getItem("Language") || process.env.VUE_APP_I18N_LOCALE || "en",
}

const getters = {}

const actions = {
  setLightDark({ commit }, val) {
    commit('setLightDark', val)
  },
  setLanguage({ commit }, val) {
    commit('setLanguage', val)
  }
}

const mutations = {
  setLightDark: (state, data) => (state.dark_theme = data),
  setLanguage: (state, data) => (state.language = data)
}

const settings = {
  state,
  getters,
  actions,
  mutations
}

export default settings

