import mock from "@/@fake-db/mock";

/* eslint-disable global-require */
const data = {
  group_levels: ["all", "sub_group", "tactic"],
  tactic_groups: ["all"],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return group_levels
// ------------------------------------------------
mock
  .onGet("/results/promo_overview/group_levels")
  .reply(() => [200, data.group_levels]);

// ------------------------------------------------
// GET: Return tactic_groups
// ------------------------------------------------
mock
  .onGet("/results/promo_overview/tactic_groups")
  .reply(() => [200, data.tactic_groups]);
