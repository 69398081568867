import mock from "@/@fake-db/mock";

/* eslint-disable global-require */
const data = {
  rx_types: ["total_rx"],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return rx_types
// ------------------------------------------------
mock.onGet("/results/summary/rx_types").reply(() => [200, data.rx_types]);
