import axios from "../axios";
import FormData from "form-data";
// import utils from "@/plugins/utils";

// const hostname = utils.getHostname();
// const baseURL = `${hostname}:8004/Vizuro/library`;
// const baseURL = `${hostname}:5000/api/v1`;
// const baseURL = "http://192.168.50.3:5000/api/v1";
// const baseURL = "http://192.168.50.3:15001/api/v1";
const baseURL = process.env.VUE_APP_API_URL;

export default {
  async getResult(table = "results", uid = "Default") {
    // table have summary, results, monthly
    const res = await axios.post(`${baseURL}/MMM/R/get_result/json`, {
      UID: uid,
      table: table,
    });
    const data = await res.data;
    console.log(data);
    return data;
  },
  async getModelInfo(status = null) {
    //../library/MMM/R/get_info/json
    let option = {};
    if (status) {
      option = { status: status };
    }
    const res = await axios.post(`${baseURL}/MMM/R/get_info/json`, option);
    const data = await res.data;
    console.log(data);
    return data;
  },
  async getModelList() {
    //../library/MMM/R/get_model/json
    const res = await axios.post(`${baseURL}/MMM/R/get_model/json`, {});
    const data = await res.data;
    console.log(data);
    return data;
    // response example:
    // ["TRY", "Vizuro2"]
  },
  async uploadData(payload) {
    const form = new FormData();
    form.append("file", payload.file);

    // the R backend API now need a string with single quote
    form.append("dataset_name", `'${payload.dataset_name}'`);
    form.append("dataset_type", `'${payload.dataset_type}'`);
    const res = await axios.post(`${baseURL}/MMM/R/upload/json`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  },
  async getDataset() {
    const res = await axios.post(`${baseURL}/MMM/R/get_info_data/json`);
    const data = res.data;
    console.log(data);
    return data;
  },
  async getBriefInfoData() {
    const res = await axios.post(`${baseURL}/MMM/R/get_BriefInfo_data/json`);
    return res.data;
  },
  async getBriefInfoModel() {
    const res = await axios.post(`${baseURL}/MMM/R/get_BriefInfo_model/json`);
    return res.data;
  },
  async getColnameCSV(UID) {
    const res = await axios.post(`${baseURL}/MMM/R/get_colname_csv/json`, {
      UID,
    });
    return res;
  },
  getTactic(UID) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_Dataset_TargetTactic/json`, {
          UID,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  reviseTactic(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/revise_Dataset_TargetTactic/json`, {
          UID: payload.UID,
          table: payload.table,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getPrice(UID) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_Dataset_Price/json`, {
          UID,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  revisePrice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/revise_Dataset_Price/json`, {
          UID: payload.UID,
          table: payload.table,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  addDatasetTacticGroup(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/add_Dataset_TacticGroup/json`, {
          UID: payload.UID,
          new_group: payload.new_group,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getDatasetTacticGroup(UID) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_Dataset_TacticGroup/json`, {
          UID,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  reviseDatasetTacticGroup(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/revise_Dataset_TacticGroup/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
          new_group_name: payload.new_group_name,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  removeDatasetTacticGroup(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/remove_Dataset_TacticGroup/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  addDatasetTacticGroupLabel(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/add_Dataset_TacticGroupLabel/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
          new_label: payload.new_label,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getDatasetTacticGroupLabel(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_Dataset_TacticGroupLabel/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  reviseDatasetTacticGroupLabel(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/revise_Dataset_TacticGroupLabel/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
          label_id: payload.l_id,
          new_label_name: payload.new_label_name,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  removeDatasetTacticGroupLabel(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/remove_Dataset_TacticGroupLabel/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
          label_id: payload.l_id,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getDatasetTacticGroupTable(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_Dataset_TacticGroupTable/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  reviseDatasetTacticGroupTable(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/revise_Dataset_TacticGroupTable/json`, {
          UID: payload.UID,
          group_id: payload.g_id,
          tactic: payload.tactic,
          label_id: payload.l_id,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getTrainInfo(UID) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_train_info/json`, {
          UID,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  getTrainStatus(UID) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/get_train_status/json`, {
          UID,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  train(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/MMM/R/train/json`, {
          UID: payload.UID,
          time_stamp: payload.time_stamp,
          segment_value: payload.segment_value,
          y_var: payload.y_var,
          x_tactic: payload.x_tactic,
          x_confounder: payload.x_confounder,
          group_id: payload.group_id,
          model_name: payload.model_name,
          model_type: payload.model_type,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  async getDatasetGroups() {
    const res = await axios.get(`${baseURL}/dataset_groups`);
    return res;
  },
  async userLogin(payload) {
    const res = await axios.post(`${baseURL}/user/login`, {
      email: payload.email,
      password: payload.password,
    });
    return res.data;
  },
  async userLogout() {
    const res = await axios.post(`${baseURL}/user/logout`);
    return res.data;
  },
  async userProfile() {
    const res = await axios.get(`${baseURL}/user/profile`);
    return res.data;
  },
  async createDatasetGroup(payload) {
    const res = await axios.post(`${baseURL}/dataset_groups`, {
      name: payload.name,
      domain: payload.domain,
      description: payload.description,
    });
    return res;
  },
  async getDatasets(id) {
    const res = await axios.get(`${baseURL}/dataset_groups/${id}/datasets`);
    return res.data.Dataset;
  },
  async getDatasetGroup(id) {
    const res = await axios.get(`${baseURL}/dataset_groups/${id}`);
    return res.data;
  },
  async updateDataset(payload) {
    const form = new FormData();
    form.append("file", payload.file);
    form.append("categoryId", payload.category_id);

    const res = await axios.put(
      `${baseURL}/dataset_groups/${payload.dataset_group_id}/datasets`,
      form,
      form,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return res;
  },
  async getDatasetGroupDomains() {
    const res = await axios.get(`${baseURL}/dataset_groups/domains`);
    return res.data.DatasetGroupDomains;
  },
  async getDatasetHeaders(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/datasets/${payload.datasetId}/headers`
    );
    return res.data.headers;
  },
  async createModel(payload) {
    const res = await axios.post(`${baseURL}/models`, payload);
    return res;
  },
  async getModels() {
    const res = await axios.get(`${baseURL}/models`);
    return res;
  },
  async getResults(modelId) {
    const res = await axios.get(`${baseURL}/results/${modelId}`);
    return res.data.Results;
  },
  async createCausalGraphModel(payload) {
    const res = await axios.post(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph`,
      {
        action: "train",
        is_acyclic: payload.is_acyclic,
        name: payload.name,
        description: payload.description,
        hyperparameters: payload.hyperparameters,
        additional_parameters: payload.additional_parameters,
      }

      // datasetGroupId: datasetGroupId,
      //         is_acyclic: this.causalGraphModel.isDag,
      //         name: this.causalGraphModel.name,
      //         description: this.causalGraphModel.description,
      //         hyperparameters: this.causalGraphModel.hyperparameters,
      //         additional_parameters: {
      //           target: this.causalGraphModel.datasetSchemaTargetValue,
      //           category_fields: this.causalGraphModel.categoryColumns,
      //         },
    );
    return res.data;
  },
  async createCausalGraph(payload) {
    const res = await axios.post(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph`,
      {
        action: "graph",
        hyperparameters: {
          dag_iterations: 20000,
          dag_lr_rate: 0.0001,
          dag_h_lambda: 1,
          dag_l2_lambda: 0.01,
        },
      }
    );
    return res.data;
  },
  async getCausalGraph(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph/graph`
    );
    return res.data;
  },
  async updateCausalGraph(payload) {
    const res = await axios.put(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph/graph`,
      {
        nodes: payload.nodes,
        edges: payload.edges,
      }
    );
    return res.data;
  },
  async getCausalGraphNodes(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph/nodes`,
      {
        params: {
          id: payload.id, // Add the query parameter
        },
      }
    );
    return res.data;
  },
  // async getCausalGraphResponseCurves(payload){
  //   const res = await axios.get(`${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph/response_curves`
  //   ,{
  //     params: {
  //           "x": payload.x,
  //           "y": payload.y,
  //       },
  //   }
  //   )
  //   return res.data
  // },
  async getCausalGraphEdges(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph/edges`
    );
    return res.data;
  },
  async getCausalGraphEdge(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph/edges`,
      {
        params: {
          x: payload.x,
          y: payload.y,
        },
      }
    );
    return res.data;
  },
  async getDatasetSchema(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/datasets/${payload.datasetId}/schemas`,
      payload
    );
    return res.data;
  },
  async updateDatasetSchema(payload) {
    const res = await axios.put(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/datasets/${payload.datasetId}/schemas`,
      {
        frequency: payload.frequency,
        schema: payload.schema,
      }
    );
    return res;
  },
  async getOpenSourceDataId(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/datasets/${payload.datasetId}/open_source_data_id`,
      payload
    );
    return res.data;
  },
  async updateOpenSourceDatasetId(payload) {
    const res = await axios.put(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/datasets/${payload.datasetId}/open_source_data_id`,
      {
        id: payload.ids,
      }
    );
    return res;
  },
  async createMergedDataset(payload) {
    const res = await axios.post(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/datasets/${payload.datasetId}/merge`,
      {
        skipped: payload.skipped,
      }
    );
    return res;
  },
  async getDomains() {
    const res = await axios.get(`${baseURL}/domains`, {
      params: {
        name: "causal_discovery", // Add the query parameter
        version: "5.2.0",
      },
    });
    return res.data;
  },
  async getExperiments(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.dsg_id}/experiments`
    );
    return res.data;
  },
  async getExperiment(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.dsg_id}/experiments/${payload.exp_id}`
    );
    return res.data;
  },
  async deleteDatasetGroup(payload) {
    const res = await axios.delete(
      `${baseURL}/dataset_groups/${payload.dataset_group_id}`
    );
    return res.data;
  },
  async createSkipCausalGraph(payload) {
    const res = await axios.post(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/causal_graph`,
      {
        action: "train",
        skipped: true,
      }
    );
    return res.data;
  },
  async updateDatasetGroup(payload) {
    const res = await axios.put(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}`,
      {
        name: payload.name,
        description: payload.description,
      }
    );
    return res;
  },
  async getModelsByDatasetGroupsId(payload) {
    const res = await axios.get(
      `${baseURL}/dataset_groups/${payload.datasetGroupId}/models`
    );
    return res;
  },
  async updateModel(payload) {
    const res = await axios.put(`${baseURL}/models/${payload.model_id}`, {
      name: payload.name,
      description: payload.description,
    });
    return res;
  },
  async updateUserProfile(payload) {
    const res = await axios.put(`${baseURL}/user/profile`, {
      username: payload.username,
    });
    return res;
  },
  async updateUserPass(payload) {
    const res = await axios.put(`${baseURL}/user/password`, {
      old_password: payload.old_password,
      new_password: payload.new_password,
    });
    return res;
  },
  async getUsers() {
    const res = await axios.get(`${baseURL}/users`, {
      params: {
        page: 1, // Add the query parameter
        siez: 100,
        order: "id",
      },
    });
    return res;
  },
  async createUser(payload) {
    const res = await axios.post(`${baseURL}/user/signup`, {
      username: payload.username,
      email: payload.email,
      password: payload.password,
    });
    return res;
  },
  async updateUser(payload) {
    const res = await axios.put(`${baseURL}/users/${payload.user_id}`, {
      active: payload.active,
    });
    return res;
  },
};
