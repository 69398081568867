import data from "@/models/data";
import axios from "../../axios";
import utils from "@/plugins/utils";

const hostname = utils.getHostname();
const baseURL = `${hostname}:8004/Vizuro/library`;

const state = {
  selectModelUID: "Default",
  modelList: [{ model_name: ["Default"], UID: ["Default"] }],
  exceljson: [],
  monthlyJson: [],
  summaryJson: [],
  rx_types: ["total_rx"],
  group_levels: ["all", "sub_group", "tactic"],
  tactic_groups: ["all"],
  selectRxType: "total_rx",
  selectGroupLevel: "all",
  selectTacticGroup: "all",
  tacticGroupObjectMetrics: [], //used for contribution to rx bar chart and table
};

const getters = {
  exceljson(state) {
    return state.exceljson;
  },
};

const actions = {
  fetchData({ commit }) {
    //fetch data from api
    return data.getResult("results", state.selectModelUID).then((req) => {
      if (req) {
        //filter json according to select state
        // const data = req.data;
        const filterJson = req
          .filter((j) => j.rx_type === state.selectRxType)
          .filter((j) => j.group_level === state.selectGroupLevel)
          .filter((j) => j.tactic_group === state.selectTacticGroup);
        //set filter json to the json state
        // const newfilterJson = filterJson.map((b, idx) => Object.assign({ index: idx }, b));

        commit("setData", filterJson);
      }
    });
  },
  async fetchModel({ commit }) {
    // console.log('getModelInfo')
    const default_model = { model_name: ["Default"], UID: ["Default"] };
    let newList = [default_model];

    const responseData = await data.getModelInfo();

    if (Array.isArray(responseData) && responseData.length > 0) {
      newList = [default_model, ...responseData];
    }

    commit("setModelList", newList);
  },
  async fetchMonthlyJson({ commit }) {
    // console.log('fetch montly json')
    const responseData = await data.getResult("monthly", state.selectModelUID);
    const filterJson = responseData.filter(
      (j) => j.rx_type === state.selectRxType
    );
    commit("setMonthlyJson", filterJson);
  },
  async fetchSummaryJson({ commit }) {
    const responseData = await data.getResult("summary", state.selectModelUID);
    const filterJson = responseData.filter(
      (j) => j.rx_type === state.selectRxType
    );
    console.log("fect summary json", responseData);
    commit("setSummaryJson", filterJson);
  },
  async setOptionsByGroupLevel({ commit }, option) {
    commit("setSelectGroupLevel", option);
    const json = await data.getResult("results", state.selectModelUID);

    //filter data with current selected Group Level
    const dataOfCurrentGroupLevel = json.filter(
      (j) => j.group_level === state.selectGroupLevel
    );
    //use the data to get the unique options for Tactic Group options
    const tactic_group_options = [
      ...new Set(dataOfCurrentGroupLevel.map((o) => o.tactic_group)),
    ];
    //set the unique options to Tactic Group options
    commit("setTacticGroups", tactic_group_options);
    //set the first unique option to the selected Tactic Group option
    commit("setSelectTacticGroup", tactic_group_options[0]);
  },
  onChangeSelectModelUID({ commit, dispatch }, option) {
    console.log("onChangeSelectModelUID", option);
    dispatch("fetchMonthlyJson");
    dispatch("fetchSummaryJson");

    commit("setSelectModelUID", option);
  },
  onChangeSelectRxType({ commit }, option) {
    commit("setSelectRxType", option);
  },
  onChangeSelectGrouopLevel({ commit }, option) {
    commit("setSelectGroupLevel", option);
  },
  onChangeSelectTacticGroup({ commit }, option) {
    commit("setSelectTacticGroup", option);
  },
  setTacticGroupObjectMetrics({ commit }, option) {
    commit("setTacticGroupObjectMetrics", option);
  },
  async onDeleteDataset(ctx, UID) {
    await axios.post(`${baseURL}/MMM/R/remove_data/json`, { UID });
  },
  async onDeleteModel(ctx, payload) {
    const [M_UID] = payload.M_UID;
    await axios.post(`${baseURL}/MMM/R/remove_model/json`, {
      M_UID,
    });
  },
};

const mutations = {
  setData: (state, data) => {
    console.log("set data commit", data);
    if (data) {
      state.exceljson = data.slice();
    }

    // state.exceljson = JSON.parse(JSON.stringify(data))
  },
  setSelectModelUID: (state, data) => (state.selectModelUID = data),
  setModelList: (state, data) => (state.modelList = data),
  setMonthlyJson: (state, data) => (state.monthlyJson = data),
  setSummaryJson: (state, data) => (state.summaryJson = data),
  setSelectRxType: (state, data) => (state.selectRxType = data),
  setSelectGroupLevel: (state, data) => (state.selectGroupLevel = data),
  setTacticGroups: (state, data) => (state.tactic_groups = data),
  setSelectTacticGroup: (state, data) => (state.selectTacticGroup = data),
  setTacticGroupObjectMetrics: (state, data) =>
    (state.tacticGroupObjectMetrics = data),
  deleteModel(state, UID) {
    state.modelList = state.modelList.filter((value) => {
      return value.UID[0] !== UID[0];
    });
  },
};

const select = {
  state,
  getters,
  actions,
  mutations,
};

export default select;
