import _ from "lodash";
//import * as path from "path";
//import dotenv from "dotenv";

export default {
  getHostname() {
    let hostname = "http://localhost";

    const envConfig = this.readConfig();
    if (envConfig && envConfig.VUE_APP_API_URL) {
      console.log("backend URL:", envConfig.VUE_APP_API_URL);
      hostname = envConfig.VUE_APP_API_URL;
    } else {
      console.log("backend URL not exist:", envConfig.VUE_APP_API_URL);
      hostname =
        window.location.hostname !== "."
          ? `http://${window.location.hostname}`
          : "http://localhost";
    }

    return hostname;
  },
  readConfig() {
    console.log(process.env);

    const envConfig = {
      ...process.env,
    };
    console.log("envConfig", envConfig);
    return envConfig;
  },
  deepCopyObject(object) {
    // return JSON.parse(JSON.stringify(object));
    return _.cloneDeep(object);
  },
};
