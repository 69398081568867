import axios from "../../axios";
// import utils from "@/plugins/utils";
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import {
  formatDecimal,
  // computeDiff,
  numFormatter,
  generateMetric,
  generateRoiMetric,
} from "@/utils";
import data from "@/models/data";

// const hostname = utils.getHostname();
// const baseURL = `${hostname}:8004/Vizuro/library`;
// const baseURL = "http://192.168.50.3:5000/api/v1";
// const baseURL = "http://192.168.50.3:15001/api/v1";
const baseURL = process.env.VUE_APP_API_URL;

const state = {
  modelList: [],
  modelID: null,
  summary: [],
  monthly: [],
  results: [],
  loaded: null,
  has_data: false,
  rx_types: [],
  group_levels: [],
  tactic_groups: [],
  summary_rx_type: null,
  summary_metrics: [],
  summary_charts: [],
  promo_overview_rx_type: null,
  promo_overview_group_levels: null,
  promo_overview_tactic_groups: null,
  promo_overview_metrics: [],
  promo_overview_charts: [],
  promo_overview_salestable: [],
  spend_simulation_response: [],
  spend_simulation_simulated_response: [],
  spend_simulation_metrics: {},
};

const getters = {
  modelList(state) {
    return state.modelList.filter((item) => {
      if (item && item.status) {
        return item.status === "Done";
      }
    });
  },
  hasData(state) {
    return state.has_data;
  },
  summaryHeaderInfo(state) {
    const obj = state.results.filter((i) => i.version.includes("post"))[0];
    if (obj) {
      const brand = obj.brand;
      const version = obj.version.replace(/_/g, " ");
      const period = obj.analytic_period;
      return { brand, version, period };
    }
    return null;
  },
  summaryPieChartData() {
    const obj = state.summary_charts.filter((i) => i.name === "pie_chart")[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  summaryBarChartData() {
    const obj = state.summary_charts.filter((i) => i.name === "bar_chart")[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  summaryLineChartData() {
    const obj = state.summary_charts.filter((i) => i.name === "line_chart")[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  promoOverviewHeaderInfo(state) {
    const obj = state.results.filter((i) => i.version.includes("post"))[0];
    if (obj) {
      const brand = obj.brand;
      const version = obj.version.replace(/_/g, " ");
      const period = obj.analytic_period;
      return { brand, version, period };
    }
    return null;
  },
  promoOverviewResponseCurveChartData() {
    const obj = state.promo_overview_charts.filter(
      (i) => i.name === "response_curve_chart"
    )[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  promoOverviewMROIChartData() {
    const obj = state.promo_overview_charts.filter(
      (i) => i.name === "MROI_chart"
    )[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  promoOverviewHorizontalChartData() {
    const obj = state.promo_overview_charts.filter(
      (i) => i.name === "horizontal_chart"
    )[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  promoOverviewHorizontalBarChartROIData() {
    const obj = state.promo_overview_charts.filter(
      (i) => i.name === "horizontal_bar_chart_ROI"
    )[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
  promoOverviewHorizontalBarChartImpactSpendData() {
    const obj = state.promo_overview_charts.filter(
      (i) => i.name === "horizontal_bar_chart_impact_spend"
    )[0];
    if (obj) {
      return obj.data;
    } else {
      return null;
    }
  },
};

const actions = {
  async getModelList({ commit }) {
    try {
      // get model list from api
      // const res = await axios.post(`${baseURL}/MMM/R/get_info/json`);
      const res = await data.getModels();
      // set model list in state
      commit("SET_MODEL_LIST", res);
    } catch (error) {
      console.log(error);
    }
  },
  async onChangeModelID({ dispatch, commit }) {
    commit("SET_LOADED", false);
    commit("RESET_RESULTS");
    return Promise.all([
      // get summary
      dispatch("getSummary"),

      // get monthly
      dispatch("getMonthly"),

      // get results
      dispatch("getResults"),
    ])
      .then((res) => {
        const success = res.findIndex((status) => status === false);
        if (success === -1) {
          commit("SET_HAS_DATA", true);
        } else {
          commit("SET_HAS_DATA", false);
        }
      })
      .catch((error) => {
        console.log(error);
        commit("SET_HAS_DATA", false);
      })
      .finally(() => {
        // using "finally" so even if there are errors, it stops "loading"
        commit("SET_LOADED", true);
      });
  },
  async getSummary({ state, commit }) {
    const modelID = state.modelID;
    // const table = "summary";
    try {
      // const res = await axios.post(`${baseURL}/MMM/R/get_result/json`, {
      //   modelID,
      //   table,
      // });
      const res = await axios.get(`${baseURL}/results/${modelID}/summary`);
      if (res.status === 200) {
        commit("SET_SUMMARY", res.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getMonthly({ state, commit }) {
    const modelID = state.modelID;
    // const table = "monthly";
    try {
      // const res = await axios.post(`${baseURL}/MMM/R/get_result/json`, {
      //   modelID,
      //   table,
      // });
      const res = await axios.get(`${baseURL}/results/${modelID}/monthly`);
      if (res.status === 200) {
        commit("SET_MONTHLY", res.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getResults({ state, commit }) {
    const modelID = state.modelID;
    // const table = "results";
    try {
      // const res = await axios.post(`${baseURL}/MMM/R/get_result/json`, {
      //   modelID,
      //   table,
      // });
      const res = await axios.get(`${baseURL}/results/${modelID}/results`);
      if (res.status === 200) {
        commit("SET_RESULTS", res.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

const mutations = {
  SET_MODEL_LIST(state, payload) {
    state.modelList = payload;
  },
  UPDATE_MODEL_ID(state, payload) {
    state.modelID = payload;
  },
  SET_SUMMARY(state, payload) {
    state.summary = payload;
  },
  SET_MONTHLY(state, payload) {
    state.monthly = payload;
  },
  SET_RESULTS(state, payload) {
    state.results = payload;
  },
  SET_RX_TYPES(state) {
    const rx_type = [...new Set(state.results.map((o) => o.rx_type))];
    state.rx_types = rx_type;
  },
  SET_SUMMARY_RX_TYPE(state, payload) {
    state.summary_rx_type = payload;
  },
  SET_SUMMARY_METRICS(state) {
    const post = state.summary.filter((i) => i.version.includes("post"))[0];
    // const pre = state.summary.filter((i) => i.version.includes("pre"))[0];
    const total_rx_col_name = state.rx_types[0];

    // utils
    function computeSummaryROI(obj) {
      const total_rx = obj[`${total_rx_col_name}`];
      const unit_price = obj.unit_price;
      const current_budget = obj.current_budget;
      const roi = ((total_rx * unit_price) / current_budget) * 0.98;
      return roi;
    }

    // tactic count
    const post_tactic_count = post.num_tactics;
    // const pre_tactic_count = pre.num_tactics;
    // const difference = post_tactic_count - pre_tactic_count;
    const tactic_count = {
      name: "Tactic Count",
      metric: post_tactic_count,
      // pre: pre_tactic_count,
      // difference,
    };

    // ROI
    const post_roi = computeSummaryROI(post);
    // const pre_roi = computeSummaryROI(pre);
    const format_post_roi = `${formatDecimal(Math.round(post_roi * 100), 0)}%`;
    // const format_pre_roi = `${formatDecimal(Math.round(pre_roi * 100), 0)}%`;
    // const differenceROI = ((post_roi - pre_roi) / pre_roi) * 100;
    // const formatDifference = formatDecimal(differenceROI, 1);
    const ROI = {
      name: "ROI",
      metric: format_post_roi,
      // pre: format_pre_roi,
      // difference: formatDifference,
      // diffSign: "%",
    };

    // model confidence
    const confidence = post.confidence_1;
    // const pre_confidence = pre.confidence_1;
    const model_ci = (1 - confidence) * 100;
    // const pre_model_ci = (1 - pre_confidence) * 100;
    const formatModelCi = `${formatDecimal(model_ci, 1)}%`;
    // const formatPreConfidence = `${formatDecimal(pre_model_ci, 1)}%`;
    // const differenceModelConfidence = computeDiff(model_ci, pre_model_ci, 1);

    const model_confidence = {
      name: "Model Confidence",
      metric: formatModelCi,
      // pre: formatPreConfidence,
      // difference: differenceModelConfidence,
      // diffSign: "%",
    };

    // total tactics impact
    const current_impact = post[`${total_rx_col_name}`];
    // const pre_current_impact = pre[`${total_rx_col_name}`];
    const formatImpact = numFormatter(current_impact, 1);
    // const preFormatImpact = numFormatter(pre_current_impact, 1);
    // const differenceTotalTacticsImpact =
    //   ((current_impact - pre_current_impact) / pre_current_impact) * 100;
    // const formatDiff = (
    //   Math.round(differenceTotalTacticsImpact * 100) / 100
    // ).toFixed(1);
    const total_tactics_impact = {
      name: "Total Tactics Impact",
      metric: formatImpact,
      // pre: preFormatImpact,
      // difference: formatDiff,
      // diffSign: "%",
    };

    // base
    const post_base = post.base_fit;
    const format_post_base = formatDecimal(post_base, 2);
    // const pre_base = pre.base_fit;
    // const format_pre_base = formatDecimal(pre_base, 2);
    // const differenceBase = computeDiff(post_base, pre_base, 1);
    const base = {
      name: "Base",
      metric: numFormatter(format_post_base, 1),
      // pre: numFormatter(format_pre_base, 1),
      // difference: differenceBase,
      // diffSign: "%",
    };

    // impactable
    // const differenceImpactable = computeDiff(post.inc_sales, pre.inc_sales, 1);
    const impactable = formatDecimal(post.inc_sales);
    // const pre_impactalbe = formatDecimal(pre.inc_sales, 2);
    const impactable_metric = {
      name: "Impactable",
      metric: numFormatter(impactable, 1),
      // pre: numFormatter(pre_impactalbe, 1),
      // difference: differenceImpactable,
      // diffSign: "%",
    };

    // impactable revenue
    let post_revenue = post.inc_sales * post.unit_price;
    // let pre_revenue = pre.inc_sales * pre.unit_price;
    // const differenceImpactableRevenue = computeDiff(
    //   post_revenue,
    //   pre_revenue,
    //   1
    // );
    post_revenue = formatDecimal(post_revenue, 2);
    // pre_revenue = formatDecimal(pre_revenue, 2);
    const impactable_revenue = {
      name: "Impactable Revenue",
      metric: numFormatter(post_revenue, 1),
      // pre: numFormatter(pre_revenue, 1),
      // difference: differenceImpactableRevenue,
      // diffSign: "%",
    };

    // tactic spend
    // const differenceTacticSpend = computeDiff(
    //   post.current_budget,
    //   pre.current_budget,
    //   1
    // );
    const tacticSpend = formatDecimal(post.current_budget, 2);
    // const pre_tactic_spend = formatDecimal(pre.current_budget, 2);
    const tactic_spend = {
      name: "Tactic Spend",
      metric: numFormatter(tacticSpend, 1),
      // pre: numFormatter(pre_tactic_spend, 1),
      // difference: differenceTacticSpend,
      // diffSign: "%",
    };

    state.summary_metrics = [
      tactic_count,
      ROI,
      model_confidence,
      total_tactics_impact,
      base,
      impactable_metric,
      impactable_revenue,
      tactic_spend,
    ];
  },
  SET_SUMMARY_CHARTS(state) {
    const post = state.summary.filter((i) => i.version.includes("post"))[0];
    const total_rx_col_name = state.rx_types[0];
    const summary_rx_type = state.summary_rx_type;

    // Regular expressions to match the two formats
    const typeARegex = /^\d{4}-\d{2}-\d{2}-\d{4}-\d{2}-\d{2}$/;
    const typeBRegex = /^\d+-\d+$/;
    let periodRangeArray = [];
    if (typeARegex.test(post.analytic_period)) {
      const [
        startYear,
        startMonth,
        startDay,
        endYear,
        endMonth,
        endDay,
      ] = post.analytic_period.split("-");
      const startTimeA = Number(
        new Date(Number(startYear), Number(startMonth) - 1, Number(startDay))
      );
      const endTimeA = Number(
        new Date(Number(endYear), Number(endMonth) - 1, Number(endDay))
      );
      periodRangeArray.push(startTimeA);
      periodRangeArray.push(endTimeA);
    } else if (typeBRegex.test(post.analytic_period)) {
      const [startTimeB, endTimeB] = post.analytic_period.split("-");
      periodRangeArray.push(startTimeB);
      periodRangeArray.push(endTimeB);
    } else {
      console.log("Unknown timestamp format.");
    }

    let chartObjArray = state.monthly
      .filter((d) => (d.rx_type = summary_rx_type))
      // .filter((i) => i.version.includes("post"))
      .filter((d) => {
        const regexDate = /^\d{4}-\d{2}-\d{2}$/;
        let time_stamp = null;
        if (regexDate.test(d.time_stamp)) {
          time_stamp = Number(new Date(d.time_stamp));
        } else {
          time_stamp = d.time_stamp;
        }
        return (
          time_stamp >= periodRangeArray[0] && time_stamp <= periodRangeArray[1]
        );
      });
    // console.log("chartObjArray", chartObjArray);
    // pie chart
    let base_ratio = (post.base_fit / (post.base_fit + post.inc_sales)) * 100;
    base_ratio = (Math.round(base_ratio * 100) / 100).toFixed(1);
    let impact_ratio = 100 - base_ratio;
    impact_ratio = (Math.round(impact_ratio * 100) / 100).toFixed(1);

    const base_legend = i18n.t("base");
    const impact_legend = i18n.t("impact");

    const pieChartData = {
      labels: [base_legend, impact_legend],
      datasets: [
        {
          data: [base_ratio, impact_ratio],
          //borderColor: '#000',
          backgroundColor: ["rgba(54, 162, 235)", "rgba(255, 206, 86)"],
          labels: [base_legend, impact_legend], //add labels again to solve the tooltip callback not showing labels issue
          borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
          borderWidth: 1,
        },
      ],
    };

    // bar chart
    // computed base ratio
    const base_data = chartObjArray.map((d) => {
      const base = d.base_fit;
      const impact = d.inc_sales;
      let ratio = (base / (base + impact)) * 100;
      return (Math.round(ratio * 100) / 100).toFixed(1); // format to 1 decimal
    });

    // impact data ratio  = 100 - base data ratio
    const impact_data = base_data.map((d) =>
      (Math.round((100 - d) * 100) / 100).toFixed(1)
    );

    const base_legend_bar_chart = i18n.t("base");
    const impact_legend_bar_chart = i18n.t("impact");
    const barChartData = {
      labels: chartObjArray.map((d) => d.time_stamp),
      datasets: [
        {
          data: base_data,
          label: base_legend_bar_chart,
          backgroundColor: "rgba(54, 162, 235, 0.9)",
          borderColor: "rgba(54, 162, 235, 0.4)",
          hoverBackgroundColor: "rgba(54, 162, 235, 0.5)",
        },
        {
          data: impact_data,
          label: impact_legend_bar_chart,
          backgroundColor: "rgba(255, 206, 86, 0.9)",
          hoverBackgroundColor: "rgba(255, 206, 86, 0.5)",
        },
      ],
    };

    // line chart
    // let actualData = chartObjArray.map((d) => Math.floor(d.total_rx));
    // let forecastData = chartObjArray.map((d) => Math.floor(d.total_rx_fit));
    let actualData = chartObjArray.map((d) =>
      Math.floor(d[`${total_rx_col_name}`])
    );
    let forecastData = chartObjArray.map((d) => Math.floor(d.base_fit));

    // console.log('actualData',actualData)
    // console.log('forecastData',forecastData)
    const actual_legend = i18n.t("actual");
    const forecast_legend = i18n.t("forecast");
    const lineChartData = {
      labels: chartObjArray.map((d) => d.time_stamp),
      datasets: [
        {
          label: actual_legend,
          data: actualData,
          fill: false,
          borderColor: "#0DD4EB",
          backgroundColor: "#0DD4EB",
        },
        {
          label: forecast_legend,
          data: forecastData,
          fill: false,
          borderColor: "#E76F86",
          backgroundColor: "#E76F86",
        },
      ],
    };

    state.summary_charts = [
      { name: "pie_chart", data: pieChartData },
      { name: "bar_chart", data: barChartData },
      { name: "line_chart", data: lineChartData },
    ];
  },
  SET_LOADED(state, payload) {
    state.loaded = payload;
  },
  SET_HAS_DATA(state, payload) {
    state.has_data = payload;
  },
  SET_GROUP_LEVELS(state) {
    const group_levels = [...new Set(state.results.map((o) => o.group_level))];
    state.group_levels = group_levels.sort();
  },
  SET_PROMO_OVERVIEW_RX_TYPE(state, payload) {
    state.promo_overview_rx_type = payload;
  },
  SET_PROMO_OVERVIEW_GROUP_LEVELS(state, payload) {
    state.promo_overview_group_levels = payload;
  },
  SET_PROMO_OVERVIEW_TACTIC_GROUPS(state, payload) {
    state.promo_overview_tactic_groups = payload;
  },
  SET_TACTIC_GROUPS(state) {
    //filter data with current selected Group Level
    const dataOfCurrentGroupLevel = state.results.filter(
      (j) => j.group_level === state.promo_overview_group_levels
    );
    //use the data to get the unique options for Tactic Group options
    const tactic_group_options = [
      ...new Set(dataOfCurrentGroupLevel.map((o) => o.tactic_group)),
    ];
    //set the unique options to Tactic Group options
    state.tactic_groups = tactic_group_options;
  },
  SET_PROMO_OVERVIEW_METRICS(state) {
    const results_filtered = state.results
      .filter((j) => j.rx_type === state.promo_overview_rx_type)
      .filter((j) => j.group_level === state.promo_overview_group_levels)
      .filter((j) => j.tactic_group === state.promo_overview_tactic_groups);
    console.log("SET_PROMO_OVERVIEW_METRICS", results_filtered);

    const post = results_filtered.filter((i) => i.version.includes("post"))[0];
    // const pre = results_filtered.filter((i) => i.version.includes("pre"))[0];

    // utils
    function computePromoOverviewROI(obj) {
      const { current_impact, current_budget, unit_price } = obj;
      const roi = ((current_impact * unit_price) / current_budget) * 0.98;
      return roi;
    }

    // ROI
    const post_roi = computePromoOverviewROI(post);
    // let pre_roi = computePromoOverviewROI(pre);
    // pre_roi = formatDecimal(pre_roi, 2);
    const formatRoi = (Math.round(post_roi * 100) / 100).toFixed(2);
    // const difference = ((post_roi - pre_roi) / pre_roi) * 100;
    // const formatDifference = formatDecimal(difference, 1);
    const ROI = {
      name: "ROI",
      metric: formatRoi,
      // pre: pre_roi,
      // difference: formatDifference,
      // diffSign: "%",
    };

    // model confidence
    const confidence = post.confidence_1;
    // const pre_confidence = pre.confidence_1;
    const model_ci = (1 - confidence) * 100;
    // const pre_model_ci = (1 - pre_confidence) * 100;
    const formatModelCi = `${formatDecimal(model_ci, 1)}%`;
    // const formatPreConfidence = `${formatDecimal(pre_model_ci, 1)}%`;
    // const differenceModelConfidence = computeDiff(model_ci, pre_model_ci, 1);

    const model_confidence = {
      name: "Model Confidence",
      metric: formatModelCi,
      // pre: formatPreConfidence,
      // difference: differenceModelConfidence,
      // diffSign: "%",
    };

    // impactable
    const current_impact = post.current_impact;
    // const pre_current_impact = pre.current_impact;
    const formatImpact = numFormatter(current_impact, 1);
    // const preFormatImpact = numFormatter(pre_current_impact, 1);
    // const differenceImpactable =
    //   ((current_impact - pre_current_impact) / pre_current_impact) * 100;
    // const formatDiff = (Math.round(differenceImpactable * 100) / 100).toFixed(
    //   1
    // );
    const impactable = {
      name: "Impactable",
      metric: formatImpact,
      // pre: preFormatImpact,
      // difference: formatDiff,
      // diffSign: "%",
    };

    // impactable revenue
    let post_revenue = post.current_impact * post.unit_price;
    // let pre_revenue = pre.current_impact * pre.unit_price;
    // const differenceImpactableRevenue = computeDiff(
    //   post_revenue,
    //   pre_revenue,
    //   1
    // );
    post_revenue = formatDecimal(post_revenue, 2);
    // pre_revenue = formatDecimal(pre_revenue, 2);
    const impactable_revenue = {
      name: "Impactable Revenue",
      metric: numFormatter(post_revenue, 1),
      // pre: numFormatter(pre_revenue, 1),
      // difference: differenceImpactableRevenue,
      // diffSign: "%",
    };

    // tactic spend
    // const differenceTacticSpend = computeDiff(
    //   post.current_budget,
    //   pre.current_budget,
    //   1
    // );
    const tacticSpend = formatDecimal(post.current_budget, 2);
    // const pre_tactic_spend = formatDecimal(pre.current_budget, 2);
    const tactic_spend = {
      name: "Tactic Spend",
      metric: numFormatter(tacticSpend, 1),
      // pre: numFormatter(pre_tactic_spend, 1),
      // difference: differenceTacticSpend,
      // diffSign: "%",
    };

    // Maximum Responsiveness
    const maximum_responsiveness = formatDecimal(post.wrs_score, 2);
    // const pre_wrs = formatDecimal(pre.wrs_score, 2);
    // const differenceWRS = computeDiff(post.wrs_score, pre.wrs_score, 1);
    const MaximumResponsiveness = {
      name: "Maximum Responsiveness",
      metric: maximum_responsiveness,
      // pre: pre_wrs,
      // difference: differenceWRS,
      // diffSign: "%",
    };

    // Current Responsiveness
    const current_responsiveness = formatDecimal(post.opportunity_index, 2);
    // const pre_oi = formatDecimal(pre.opportunity_index, 2);
    // const differenceOI = computeDiff(
    //   post.opportunity_index,
    //   pre.opportunity_index,
    //   1
    // );
    const CurrentResponsiveness = {
      name: "Current Responsiveness",
      metric: current_responsiveness,
      // pre: pre_oi,
      // difference: differenceOI,
      // diffSign: "%",
    };

    state.promo_overview_metrics = [
      ROI,
      model_confidence,
      impactable,
      impactable_revenue,
      tactic_spend,
      MaximumResponsiveness,
      CurrentResponsiveness,
    ];
  },
  SET_PROMO_OVERVIEW_CHARTS(state) {
    const results_filtered = state.results
      .filter((j) => j.rx_type === state.promo_overview_rx_type)
      .filter((j) => j.group_level === state.promo_overview_group_levels)
      .filter((j) => j.tactic_group === state.promo_overview_tactic_groups);

    // utils
    function computeChartData(arr) {
      if (!arr) {
        return;
      }
      const obj = arr.filter((a) => a.version.includes("post"));
      const factors = [
        0,
        0.1,
        0.2,
        0.3,
        0.4,
        0.5,
        0.6,
        0.7,
        0.8,
        0.9,
        1.0,
        1.1,
        1.2,
        1.3,
        1.4,
        1.5,
        1.6,
        1.7,
        1.8,
        1.9,
        2.0,
      ];
      const {
        l,
        k,
        x_0,
        unit_price,
        optimized_budget,
        optimized_impact,
        current_budget,
      } = obj[0];
      const optimized_sales = optimized_impact * unit_price;

      let xValues = factors.map((factor) => current_budget * factor);
      // xValues = [...xValues, optimized_budget].sort((a, b) => a - b); //sort accendingly
      xValues = [...xValues].sort((a, b) => a - b); //sort accendingly
      let yValues = factors.map(
        (factor) => (l / (1 + Math.exp(-k * (factor - x_0)))) * unit_price
      );
      // yValues = [...yValues, optimized_sales].sort((a, b) => a - b);
      yValues = [...yValues].sort((a, b) => a - b);

      let lineData = xValues.map((xVal, i) => ({ x: xVal, y: yValues[i] }));

      return {
        current_budget,
        optimized_budget,
        optimized_sales,
        lineData,
      };
    }

    function computeMROI(arr) {
      if (!arr) {
        return;
      }
      const obj = arr.filter((a) => a.version.includes("post"));
      const factors = [
        0,
        0.1,
        0.2,
        0.3,
        0.4,
        0.5,
        0.6,
        0.7,
        0.8,
        0.9,
        1.0,
        1.1,
        1.2,
        1.3,
        1.4,
        1.5,
        1.6,
        1.7,
        1.8,
        1.9,
        2.0,
      ];
      const { l, k, x_0, current_budget, unit_price } = obj[0];

      let xValues = factors.map((factor) => current_budget * factor);
      const yValues = factors.map(
        (factor) =>
          (((unit_price * k * l) /
            Math.pow(1 + Math.exp(-k * (factor - x_0)), 2)) *
            Math.exp(-k * (factor - x_0))) /
            current_budget -
          1
      );
      const indexOfyValuesMax = yValues.indexOf(Math.max(...yValues));

      const MROIData = xValues.map((xVal, i) => ({ x: xVal, y: yValues[i] }));
      return {
        indexOfyValuesMax,
        MROIData,
      };
    }

    // settings
    const theme = vuetify.framework.theme.dark ? "dark" : "light";
    const borderColor = vuetify.framework.theme.themes[theme].borderColor;

    // response curve chart
    const {
      lineData,
      current_budget,
      optimized_budget,
      optimized_sales,
    } = computeChartData(results_filtered);

    let responseCurveChartData = {
      datasets: [
        {
          label: "Sales",
          //fill: false,
          borderColor: borderColor,
          pointBackgroundColor: borderColor,
          pointHoverRadius: 8,
          data: lineData,
          current_budget,
          optimized_budget,
          optimized_sales,
        },
      ],
    };

    // MROI chart
    const { MROIData, indexOfyValuesMax } = computeMROI(results_filtered);
    const MROIChartData = {
      datasets: [
        {
          label: "Sales",
          fill: false,
          borderColor: "#E76F86", //this.borderColor,
          pointBackgroundColor: "#E76F86", //this.borderColor,
          pointHoverRadius: 10,
          data: MROIData,
          indexOfyValuesMax,
        },
      ],
    };

    // horizontal chart
    const {
      promo_overview_rx_type,
      promo_overview_group_levels,
      promo_overview_tactic_groups,
    } = state;

    let revisedJson = state.results;
    const currentImpactOfallTactic = revisedJson.filter(
      (j) =>
        j.rx_type === promo_overview_rx_type &&
        j.group_level === "All" &&
        j.tactic_group === "All" &&
        j.version.includes("post")
    )[0].current_impact;

    let filterRevisedJson = revisedJson
      .filter((j) => j.rx_type === promo_overview_rx_type)
      .filter((j) => j.group_level === promo_overview_group_levels)
      .filter((j) => j.version.includes("post"));

    //store unique objects whose tact_group match any of tactic_groups
    const barObject = {};

    filterRevisedJson.map((j) => {
      if (!barObject[j.tactic_group]) {
        barObject[j.tactic_group] = j; //j.current_impact/currentImpactOfallTactic
      }
    });

    //use barOjbect to create an array of objects that can be sorted by value
    // let barObjectArray = Object.keys(barObject).map(o => ({ label: o, data: barObject[o] }))
    // barObjectArray = barObjectArray.sort((a, b) => b.data - a.data)
    let barObjectArray = Object.keys(barObject).map((key) => {
      const label = key;
      const {
        current_impact,
        current_budget,
        wrs_score: maximum_responsiveness,
        opportunity_index: current_responsiveness,
        unit_price,
      } = barObject[key];
      const contribution_to_rx = current_impact / currentImpactOfallTactic;
      const roi = (current_impact * unit_price * 0.98) / current_budget;
      const impactable_revenue = current_impact * unit_price;

      return {
        label: label,
        impactable_rx: current_impact,
        impactable_revenue,
        tactic_spend: current_budget,
        contribution_to_rx,
        roi,
        maximum_responsiveness,
        current_responsiveness,
        key: label,
      };
    });
    barObjectArray = barObjectArray.sort(
      (a, b) => b.contribution_to_rx - a.contribution_to_rx
    );

    const horizontalData = {
      labels: barObjectArray.map((o) => o.label), //tactic_groups,
      datasets: [
        {
          label: "Data One",
          data: barObjectArray.map((o) => o.contribution_to_rx), //Object.values(barObject),//test.map(t => t.current_impact),
          backgroundColor: barObjectArray.map((o) =>
            o.key === promo_overview_tactic_groups ? "#F7C23F" : "#EDE4E3"
          ), //['#2DAB7C', '#ffb86f', '#e0ca3c'],
        },
      ],
    };

    const barObjectROIArray = barObjectArray.sort((a, b) => b.roi - a.roi);

    // ROI
    const horizontalBarChartROIData = {
      labels: barObjectROIArray.map((o) => o.label),
      datasets: [
        {
          label: "ROI",
          data: barObjectROIArray.map((o) => o.roi),
          backgroundColor: "rgba(54, 162, 235)",
        },
      ],
    };

    // Impact, Spend
    const horizontalBarChartImpactSpendData = {
      labels: barObjectROIArray.map((o) => o.label),
      datasets: [
        {
          label: "Impact",
          data: barObjectROIArray.map((o) => o.impactable_rx),
          backgroundColor: "rgba(54, 162, 235)",
        },
        {
          label: "Spend",
          data: barObjectROIArray.map((o) => o.tactic_spend),
          backgroundColor: "rgba(255, 206, 86)",
        },
      ],
    };

    // sales table
    state.promo_overview_salestable = barObjectArray;

    state.promo_overview_charts = [
      { name: "response_curve_chart", data: responseCurveChartData },
      { name: "MROI_chart", data: MROIChartData },
      { name: "horizontal_chart", data: horizontalData },
      { name: "horizontal_bar_chart_ROI", data: horizontalBarChartROIData },
      {
        name: "horizontal_bar_chart_impact_spend",
        data: horizontalBarChartImpactSpendData,
      },
    ];
  },
  SET_SPEND_SIMULATION(state) {
    const options = [
      { text: "-50%", value: 0.5 },
      { text: "+0%", value: 1 },
      { text: "+50%", value: 1.5 },
      { text: "+100%", value: 2 },
    ];

    // utils
    function computeTableArray(tacticObject) {
      const tableArray = Object.keys(tacticObject).map((key) => {
        const {
          current_budget,
          current_impact,
          optimized_budget,
          optimized_impact,
          unit_price,
          l,
          k,
          x_0,
        } = tacticObject[key];
        const current_impact_revenue = current_impact * unit_price;
        const optimized_impact_revenue = optimized_impact * unit_price;
        const tactic = key;
        const option = options;
        const selectOption = option[1];
        const simulated_budget = current_budget * selectOption.value;
        const simulated_impact = current_impact * selectOption.value;
        const simulated_revenue = current_impact_revenue * selectOption.value;
        const current_roi =
          ((current_impact * unit_price) / current_budget) * 0.98;
        const optimized_roi =
          ((optimized_impact * unit_price) / optimized_budget) * 0.98;
        const simulated_roi =
          ((simulated_impact * unit_price) / simulated_budget) * 0.98;
        return {
          option,
          unit_price,
          l,
          k,
          x_0,
          selectOption,
          tactic,
          current_budget,
          optimized_budget,
          simulated_budget,
          current_impact,
          optimized_impact,
          simulated_impact,
          current_impact_revenue,
          optimized_impact_revenue,
          simulated_revenue,
          current_roi,
          optimized_roi,
          simulated_roi,
        };
      });
      return tableArray;
    }

    // const rx_type = "total_rx";
    const rx_type = state.rx_types[0];
    const group_level = "tactic";
    const allTacticJson = state.results.filter(
      (j) =>
        j.rx_type === rx_type &&
        j.group_level === group_level &&
        j.version.includes("post")
    );
    const tacticObject = {};
    allTacticJson.map((j) => {
      if (!tacticObject[j.tactic_group]) {
        tacticObject[j.tactic_group] = j;
      }
    });

    const tableObjectArray = computeTableArray(tacticObject);
    state.spend_simulation_response = tableObjectArray;
    state.spend_simulation_simulated_response = cloneDeep(
      state.spend_simulation_response
    );

    const Spend = generateMetric(
      state.spend_simulation_simulated_response,
      "current_budget",
      "optimized_budget",
      "simulated_budget"
    );
    const Impact = generateMetric(
      state.spend_simulation_simulated_response,
      "current_impact",
      "optimized_impact",
      "simulated_impact"
    );
    const Revenue = generateMetric(
      state.spend_simulation_simulated_response,
      "current_impact_revenue",
      "optimized_impact_revenue",
      "simulated_revenue"
    );
    const ROI = generateRoiMetric(
      Impact,
      state.spend_simulation_simulated_response[0].unit_price,
      Spend
    );

    state.spend_simulation_metrics = {
      Spend,
      Impact,
      Revenue,
      ROI,
    };
  },
  UPDATE_SPEND_SIMULATED_RESPONSE(state, payload) {
    state.spend_simulation_simulated_response = map(
      state.spend_simulation_simulated_response,
      (value) => {
        if (payload.tactic === value.tactic) {
          const scale = payload.selectOption;
          // update data for selected row
          const simulated_budget = value.current_budget * scale;
          const simulated_impact =
            value.l / (1 + Math.exp(-value.k * (scale - value.x_0)));
          const simulated_revenue = simulated_impact * value.unit_price;
          const simulated_roi =
            ((simulated_impact * value.unit_price) / simulated_budget) * 0.98;
          return {
            ...value,
            selectOption: scale,
            simulated_budget,
            simulated_impact,
            simulated_revenue,
            simulated_roi,
          };
        } else {
          return value;
        }
      }
    );

    // update data for SpendSimulationMetrics component
    const Spend = generateMetric(
      state.spend_simulation_simulated_response,
      "current_budget",
      "optimized_budget",
      "simulated_budget"
    );
    const Impact = generateMetric(
      state.spend_simulation_simulated_response,
      "current_impact",
      "optimized_impact",
      "simulated_impact"
    );
    const Revenue = generateMetric(
      state.spend_simulation_simulated_response,
      "current_impact_revenue",
      "optimized_impact_revenue",
      "simulated_revenue"
    );
    const ROI = generateRoiMetric(
      Impact,
      state.spend_simulation_simulated_response[0].unit_price,
      Spend
    );

    state.spend_simulation_metrics = {
      Spend,
      Impact,
      Revenue,
      ROI,
    };
  },
  RESET_SPEND_SIMULATED_RESPONSE(state) {
    state.spend_simulation_simulated_response = cloneDeep(
      state.spend_simulation_response
    );

    const Spend = generateMetric(
      state.spend_simulation_simulated_response,
      "current_budget",
      "optimized_budget",
      "simulated_budget"
    );
    const Impact = generateMetric(
      state.spend_simulation_simulated_response,
      "current_impact",
      "optimized_impact",
      "simulated_impact"
    );
    const Revenue = generateMetric(
      state.spend_simulation_simulated_response,
      "current_impact_revenue",
      "optimized_impact_revenue",
      "simulated_revenue"
    );
    const ROI = generateRoiMetric(
      Impact,
      state.spend_simulation_simulated_response[0].unit_price,
      Spend
    );

    state.spend_simulation_metrics = {
      Spend,
      Impact,
      Revenue,
      ROI,
    };
  },
  RESET_RESULTS(state) {
    state.summary = [];
    state.monthly = [];
    state.results = [];
    state.has_data = false;
    state.rx_types = [];
    state.group_levels = [];
    state.tactic_groups = [];
    state.summary_rx_type = null;
    state.summary_metrics = [];
    state.summary_charts = [];
    state.promo_overview_rx_type = null;
    state.promo_overview_group_levels = null;
    state.promo_overview_tactic_groups = null;
    state.promo_overview_metrics = [];
    state.promo_overview_charts = [];
    state.promo_overview_salestable = [];
    state.spend_simulation_response = [];
    state.spend_simulation_simulated_response = [];
    state.spend_simulation_metrics = {};
  },
};

const results = {
  state,
  getters,
  actions,
  mutations,
};

export default results;
