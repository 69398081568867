<template>
  <layout-content-vertical-nav>
    <slot></slot>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from "@/core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue";

export default {
  components: {
    LayoutContentVerticalNav,
  },
};
</script>

<style lang="scss" scoped>
</style>
