import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./assets/_variables.scss";
import Vuelidate from "vuelidate";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "@/@fake-db/db";
import VuePapaParse from 'vue-papa-parse'
import dotenv from 'dotenv'

dotenv.config()
Vue.config.productionTip = false;
Vue.use(vuetify);
Vue.use(VuePapaParse)
Vue.use(Vuelidate);
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
