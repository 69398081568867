import select from "./select";
import settings from "./settings";
import { loader } from "./loader";
import results from "./results";

const modules = {
  select,
  settings,
  loader,
  results,
};

export default modules;
